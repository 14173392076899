import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import HeaderPaginas from "../components/headerPaginas"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/fabricantes.scss"

export default function fabricantes(props) {
  return (
    <div>
      <Layout>
        <Seo pathname={props.location.pathname} />
        <HeaderPaginas
          titulo="Fabricantes"
          imagen="/img/cabeceras/fabricantes_bullhost.jpg"
        />
        {/* LOGOS FABRICANTES */}
        <section className="fabricante-fila">
          <div className="limitador">
            <div className="fabricantes-fila-titulo">
              <h2>Partners</h2>
              <p>
                Ser un buen partner para tu empresa nos exige, a su vez, que
                nosotros trabajemos con los mejores fabricantes del mercado y,
                que además, seamos un referente para ellos. Bullhost se erige
                como partner potente de las mejores marcas del mercado
                internacional.
              </p>
            </div>
            <div className="iconos-fabricantes">
              <div className="card-icono partner">
                <a
                  href="https://www.kaspersky.es/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/kaspersky-logo.svg"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Kaspersky"
                  />
                </a>
                <div>
                  <p>Gold Partner</p>
                </div>
              </div>

              <div className="card-icono partner">
                <a
                  href="https://www.logpoint.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/logpoint-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Logpoint"
                  />
                </a>
                <div>
                  <p>Único MSSP Partner en España</p>
                </div>
              </div>

              <div className="card-icono partner">
                <a
                  href="https://www.sophos.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/sophos-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Sophos"
                  />
                </a>
                <div>
                  <p>Silver Partner</p>
                </div>
              </div>

              <div className="card-icono partner">
                <a
                  href="https://aws.amazon.com/es"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/amazon-partner.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Amazon Web Services"
                  />
                </a>
              </div>

              <div className="card-icono partner">
                <a
                  href="https://cisco.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/cisco-partner-black.svg"
                    style={{ width: 250 }}
                    objectFit="contain"
                    alt="Cisco"
                  />
                </a>
              </div>

              <div className="card-icono partner">
                <a
                  href="https://www.veeam.com/es/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/veeam-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Veeam"
                  />
                </a>
                <div>
                  <p>Silver Partner</p>
                </div>
              </div>

              <div className="card-icono partner">
                <a
                  href="https://www.paloaltonetworks.es/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/paloalto-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Palo Alto"
                  />
                </a>
              </div>

              <div className="card-icono partner">
                <a
                  href="https://forescouttechnologies.es/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/forescout-logo.svg"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Forescout"
                  />
                </a>
                <div>
                  <p>Silver Partner</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fabricante-fila">
          <div className="limitador">
            <div className="fabricantes-fila-titulo">
              <h2>Otros fabricantes</h2>
            </div>
            <div className="iconos-fabricantes">
              <div className="card-icono">
                <a
                  href="https://www.fortinet.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/logo-fortinet.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Fortinet"
                  />
                </a>
              </div>

              <div className="card-icono">
                <a
                  href="https://www.arubanetworks.com/es/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/aruba-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Aruba Networks"
                  />
                </a>
              </div>

              <div className="card-icono">
                <a
                  href="https://www.microsoft.com/es-es"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/microsoft-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Microsoft"
                  />
                </a>
              </div>

              <div className="card-icono">
                <a
                  href="https://www.nozominetworks.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/nozomi-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Nozomi Networks"
                  />
                </a>
              </div>

              <div className="card-icono">
                <a
                  href="https://www.hp.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/hp-logo.png"
                    style={{ maxHeight: 100 }}
                    objectFit="contain"
                    alt="HP"
                  />
                </a>
              </div>

              <div className="card-icono">
                <a
                  href="https://www.netskope.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/netskope-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Netskope"
                  />
                </a>
              </div>

              <div className="card-icono">
                <a
                  href="https://www.proofpoint.com/es"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/proofpoint-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Proofpoint"
                  />
                </a>
              </div>
              <div className="card-icono">
                <a
                  href="https://www.safetica.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/safetica-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Safetica"
                  />
                </a>
              </div>

              <div className="card-icono">
                <a
                  href="https://telepathy.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/telepathy-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Telepathy"
                  />
                </a>
              </div>
              <div className="card-icono">
                <a
                  href="https://www.varonis.com/es/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <StaticImage
                    src="../../static/img/logos-fabricantes/varonis-logo.png"
                    style={{ maxWidth: 250 }}
                    objectFit="contain"
                    alt="Varonis"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}
